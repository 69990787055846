import axios from "axios";
import Utils from "../utils";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.authorization = Utils.getTokenFromStorage();
    config.timeout = 600000;
    return config;
  },
  (error) => Promise.reject(error)
);

class HttpService {
  get = (suffix) =>
    new Promise((resolve, reject) => {
      axiosInstance.get(suffix).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  post = (suffix, payload) =>
    new Promise((resolve, reject) => {
      axiosInstance.post(suffix, payload).then(
        (response) => {
          response.data.code === 0
            ? resolve(response.data)
            : reject(response.data.message);
        },
        (error) => {
          reject(this.handleErrorResponse(error));
          this.dealExipratedToken(suffix, error.response?.data.code);
        }
      );
    });

  handleErrorResponse = (error) => {
    return error.response
      ? // error.response?.data.message ? `${error.response.data.message} | code: ${error.response.data.code}` : error.message) :
        error.response?.data.message
        ? `${error.response.data.message}`
        : error.message
      : error.message;
  };

  dealExipratedToken = (suffix, code) => {
    if (suffix.includes("api") && code === 401) {
      Promise.resolve(Utils.deleteItemFromStorage(Utils.TOKEN_META_KEY)).then(
        () => {
          window.location.reload(false);
        }
      );
    }
  };
}
export default new HttpService();

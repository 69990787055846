import { HttpService } from ".";


class UserService {

    login = ({ username, password }) => {
        return HttpService.post('/auth/generate-token', { username, password });
    }

    logout = () => {
        return HttpService.get('/api/logout');
    }


} export default new UserService();
import React from 'react';

export const Input = (props) => {
    return (
        <div class="form-group">
            {props.label && <label for={props.name}>{props.label}</label>}
            <div className="input-group">
                <span class="input-group-prepend">
                    <div class="input-group-text bg-transparent border-right-0">
                        <i class={props.leftIcon} />
                    </div>
                </span>
                <input
                    id={props.id}
                    type={props.type}
                    placeholder={props.placeholder}
                    class={`form-control ${props.className}`}
                    name={props.id}
                    value={props.value}
                    required={props.required}
                    onChange={props.onChange}
                    autofocus />
            </div>
        </div>
    );
}
import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { useHistory } from "react-router";
import { Popup, Dropdown } from "../shared";
import utils from "../../utils";

import { Logout } from "../pages/logout";
import { Button } from "primereact/button";
import "./header.scss";

export const Header = (props) => {
  const isActiveLink = (route) => {
    return history.location.pathname.includes(route);
  };
  const history = useHistory();
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [confirmlogout, setConfirmlogout] = useState(false);
  const [logout, setLogout] = useState(false);
  const [menuItems, setMenuItems] = useState(
    utils.isAdmin()
      ? [
          {
            label: "Home",
            icon: "fas fa-home",
            action: () => history.push("/home"),
            className: isActiveLink("home") && "active-link",
          },
          {
            label: "Admin Panel",
            icon: "fas fa-user-cog",
            action: () => history.push("/admin"),
            className: isActiveLink("admin") && "active-link",
          },
        ]
      : [
          {
            label: "Home",
            icon: "fas fa-home",
            action: () => history.push("/home"),
            className: isActiveLink("home") && "active-link",
          },
        ]
  );

  return (
    <nav className="navbar navbar-light custom-header shadow">
      <div className="left-nav ml-5">
        <a className="navbar-brand" href="home">
          <img src={logo} width={220} alt="logo" />
        </a>
      </div>
      <div className="right-nav mr-4">
        <Dropdown
          toggleLabel={utils.getSubFromToken()}
          toggleIcon="far fa-user-circle"
          items={menuItems}
          footer={{
            label: "Logout",
            icon: "fas fa-sign-out-alt",
            action: () => setDisplayConfirmation(true),
          }}
        />

        <Popup
          headerIcon="bi bi-question-circle"
          title="Confirmation"
          visible={displayConfirmation}
          //dismissable
          onClose={() => setDisplayConfirmation(false)}
          content={
            <div className="confirmation-content">
              <i
                className="pi pi-question-circle"
                style={{ fontSize: "2rem", marginRight: "0.5rem" }}
              />{" "}
              <span>Are you sure you want to logout?</span>
            </div>
          }
          footer={
            confirmlogout ? (
              <Logout />
            ) : (
              <div className="d-flex float-right">
                  <Button
                      type="button"
                      label="No"
                      //icon='pi pi-times'
                      style={{
                          //borderRadius: '50px',
                          backgroundColor: '#ED1C24', border: '1px solid transparent', background: '#ED1C24'} }
                      onClick={() => setDisplayConfirmation(false)}
                  />
                &nbsp;
                <Button
                  type="button"
                  label="Yes"
                  //id='yes-btn'
                  //className="btn btn-primary btn-block"
                  style={{
                      //borderRadius: '50px',
                      backgroundColor: '#ED1C24', border: '1px solid transparent', background: '#ED1C24!important'} }
                  onClick={() => setConfirmlogout(true)}
                />
              </div>
            )
          }
        />
      </div>
    </nav>
  );
};

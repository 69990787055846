import axios from "axios";
import Utils from "../utils";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.authorization = Utils.getTokenFromStorage();
    config.responseType = "blob";
    return config;
  },
  (error) => Promise.reject(error)
);

class Reporting {
  downloadReport = (url, startDate, endDate, setLoadingDownload) => {
    let base_url = process.env.REACT_APP_API_URL + "/api/reporting/idea-report";
    let filename = "IdeaReport";
    if (url !== null) {
      base_url = base_url + "?" + url;
      filename = filename + "-" + startDate + "-" + endDate;
    }
    setLoadingDownload(true);
    axiosInstance.get(base_url).then(
      (response) => {
        setLoadingDownload(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.setAttribute("download", filename + ".xlsx");
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      },
      (error) => {
        setLoadingDownload(false);
        alert(JSON.stringify(error));
      }
    );
  };
}
export default new Reporting();

import { HttpService } from ".";

class QuestionnaireService {
  getThemes = () => {
    return HttpService.get("/api/theme");
  };

  getUserInfo = () => {
    return HttpService.get("/api/user-info");
  };

  addNewIdea = (idea) => {
    return HttpService.post("/api/idea", idea);
  };
}
export default new QuestionnaireService();

import JWTDecoder from "jwt-decoder-claims";
import { Spinner } from "../components/shared";

class Utils {
  //BEARER TOKEN META KEY
  TOKEN_META_KEY = "BEARER_AUTHORIZATION_IDEABOX";
  REMEMBER_USER_META_KEY = "REMEMBER_ME";

  //PERSIST ITEM IN LOCAL STORAGE
  setItemToStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  //DELETE ITEM FROM LOCAL STORAGE
  deleteItemFromStorage = (key) => {
    localStorage.removeItem(key);
  };

  //GET ITEM FROM LOCALSTORAGE
  getItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  //GET USERNMAE FROM TOKEN
  getSubFromToken = () => {
    return this.decodeJWT()?.sub;
  };

  //GET BEARER TOKEN FROM STORAGE
  getTokenFromStorage = () => {
    return JSON.parse(localStorage.getItem(this.TOKEN_META_KEY));
  };

  //DECODING BEARER TOKEN
  decodeJWT = () => {
    const token = this.getTokenFromStorage()?.replace("Bearer ", "");
    return token ? JWTDecoder.payload(token) : null;
  };

  //RESOLVE USER ROLES FROM TOKEN
  getRolesFromToken = () => {
    return this.decodeJWT()?.ROLES;
  };

  //
  isAdmin = () => {
    return this.getRolesFromToken()?.includes("ROLE_IDEA_ADMIN");
  };

  //CHECK IF USER IS LOGGED
  isUserLogged = () => {
    try {
      //return this.decodeJWT()?.sub !== '' || this.decodeJWT()?.sub !== null;
      return this.getItemFromLocalStorage(this.TOKEN_META_KEY).length !== 0;
    } catch (err) {
      return false;
    }
  };

  labelTemplate = (template, loading, label) => {
    return loading ? <Spinner color="white" sm label={label} /> : template;
  };

  //MANAGE PERSISTANCE OF USER CREDENTIALS
  manageCacheUserCredentials = (credentials) => {
    if (this.getItemFromLocalStorage(this.REMEMBER_USER_META_KEY)) {
      this.setItemToStorage("username", credentials.username);
      this.setItemToStorage("password", credentials.password);
    } else {
      this.deleteItemFromStorage("username");
      this.deleteItemFromStorage("password");
    }
  };

  initialCredentialValue = (field) => {
    return this.getItemFromLocalStorage(this.REMEMBER_USER_META_KEY)
      ? this.getItemFromLocalStorage(field)
      : null;
  };
}
export default new Utils();

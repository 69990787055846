import React from "react";
import { Route, Redirect } from "react-router-dom";
import Utils from "../../utils";
import { AccessDenied } from "./access-denied";
import { AdministratorView } from "../pages/administrator-view";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = () => {
    return Utils.isUserLogged();
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <>
            {rest.isAdmin ? (
              Utils.isAdmin() ? (
                <Component {...props} />
              ) : (
                <AccessDenied />
              )
            ) : (
              <Component {...props} />
            )}
          </>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

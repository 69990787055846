import { Routes } from "./components/navigation/";
import {Header} from "./components/layout";
import React from "react";

function App() {
  return (
      <div>
        <Routes/>
      </div>
  );
}

export default App;

import { TextField } from "@material-ui/core";
import React from "react";

const CustomInput = (props) => {
  return (
    <TextField
      id={props.id}
      label={props.label}
      type={props.type}
      select={props.select}
      InputProps={props.InputProps}
      style={props.style}
      value={props.value}
      onChange={props.onChange}
      required={true}
      margin="dense"
      variant="outlined"
    >
      {props.children}
    </TextField>
  );
};
export { CustomInput };

import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';

export const Dropdown = (props) => {


    const DropdownItem = (props) => {
        return (
            <span className={`dropdown-item ${props.className}`} style={{ cursor: "pointer" }} onClick={props.action}><i className={props.icon} /> {props.label}</span>
        );
    }

    return (
        <div className="nav-item dropdown mr-5">
            <span className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className={props.toggleIcon} style={{ fontSize: "20px" }} /> {props.toggleLabel}</span>
            <div className="dropdown-menu">
                {
                    props.items.map((item, key) => {
                        return <DropdownItem key={key} label={item.label} icon={item.icon} action={item.action} className={item.className} />
                    })
                }
                {
                    props.footer && <>
                        <div className="dropdown-divider"></div>
                        <DropdownItem label={props.footer.label} icon={props.footer.icon} action={props.footer.action} />                    </>
                }
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import UserService from '../../api/user-service';
import Utils from '../../utils';
import { Spinner } from "../shared";
import { useHistory } from 'react-router';


export const Logout = () => {
    const history = useHistory();
    const [loader, setLoader] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        logout();
    }, []);


    function logout() {
        setLoader(true);
        UserService.logout().then(() => {
            Promise.resolve(Utils.deleteItemFromStorage(Utils.TOKEN_META_KEY)).then(() => {
                setTimeout(() => {
                    window.location.reload(false);

                }, 600);
                history.push("/login");
            })
        });
    }

    return (
        <>
            {loader && <Spinner color="#ED1C24" label="Logging out..." />}
            {error && <span className='logging-out-loader'>{error}</span>}
        </>
    );
}
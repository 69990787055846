import React from "react";
import { Header } from "../layout";

export const AccessDenied = () => {
  return (
    <>
      <Header />
      <div className="border border-light p-3 mb-4">
        <div className="text-center">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "3rem" }}
          />
          <div>
            <span>
              Vous n'avez pas de privilèges d'accès, veuillez s'il vous plait
              contacter votre administrateur
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

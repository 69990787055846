import React, { useState } from "react";
import getIdea from "../../assets/img/getIdea.jpg";
import { Input, Button, Alert } from "../shared";
import logo from "../../assets/img/logo.png";
import { UserService } from "../../api";
import { useHistory } from "react-router";
import Utils from "../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState();
  const history = useHistory();

  //LOGIN FORMIK
  const formik = useFormik({
    initialValues: {
      username: Utils.initialCredentialValue("username"),
      password: Utils.initialCredentialValue("password"),
    },
    validationSchema: Yup.object({
      username: Yup.mixed().required("Username field should not be empty!"),
      password: Yup.mixed().required("Password field should not be empty!"),
    }),

    onSubmit: (values) => {
      submitLoginForm(values);
    },
  });

  //LOGIN FORM SUBMISSION
  const submitLoginForm = (payload) => {
    setLoading(true);
    Utils.manageCacheUserCredentials(payload);
    UserService.login(payload).then(
      (response) => {
        onLoginSuccess(response.authorization);
      },
      (error) => {
        onLoginFailure(error);
      }
    );
  };

  //ON SUCCESS AUTHENTIFICATION
  const onLoginSuccess = (token) => {
    Promise.resolve(Utils.setItemToStorage(Utils.TOKEN_META_KEY, token)).then(
      () => {
        history.push("/home");
      }
    );
  };

  //ON LOGIN FAILURE
  const onLoginFailure = (error) => {
    setLoading(false);
    setErrorForm(error);
  };

  return (
    <div class="container">
      <div class="row py-5 mt-4 justify-content-center">
        {/* <!-- Login Form --> */}

        <section class="h-200 col-md-5">
          <div class="container h-100">
            <div class="card-wrapper w-100">
              <div class="brand text-center">
                <br />
                <img src={logo} alt="logo" />
              </div>
              <br />
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title text-center">Login</h4>
                  <form
                    onSubmit={formik.handleSubmit}
                    class="my-login-validation"
                  >
                    <Input
                      id="username"
                      type="text"
                      leftIcon="fa fa-user"
                      required
                      placeholder="Username"
                      value={formik.values.username}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setErrorForm(false);
                      }}
                      msgValidation={
                        formik.touched.username && formik.errors.username
                      }
                    />
                    <Input
                      id="password"
                      type="password"
                      leftIcon="fa fa-lock"
                      placeholder="Password"
                      required
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setErrorForm(false);
                      }}
                      msgValidation={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <Button
                      rounded
                      type="submit"
                      disabled={loading}
                      label={Utils.labelTemplate(
                        <>
                          <i className="fas fa-check-circle" /> submit
                        </>,
                        loading,
                        "Logging in..."
                      )}
                    />
                    <div class="mt-4 text-center form-bottom"></div>
                  </form>
                </div>
              </div>
              <div class="footer text-center copyright">
                Copyright &copy; 2021 &mdash; Ooredoo
              </div>
            </div>

            {errorForm && (
              <Alert type="danger" icon="bi bi-x-circle" content={errorForm} />
            )}
          </div>
        </section>
        {/* <!-- right side --> */}

        <div
          class="col-md-5 pr-lg- mb-md-0 mx-5 d-none d-md-block"
          style={{ marginTop: "-50px" }}
        >
          <img
            src={getIdea}
            alt=""
            class="img-fluid mb-0"
            style={{ width: "80%" }}
          />
          <h1>Sign in</h1>
          <p class=" text-muted mb-0 sign-label">
            Welcome to the login page, please sign in to continue.
          </p>
          <p class="text-muted">
            Made By{" "}
            <a
              href="mailto:DigitalTechnologyGroup@ooredoo.dz"
              class="text-muted"
            >
              <u>Ooredoo Digital Team</u>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

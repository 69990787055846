import React, { useState, useEffect, useRef } from "react";
import {
  ToastNotification,
  ProgressLoader,
  MaterialTableCustom,
  Input,
  Button,
} from "../shared";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Utils from "../../utils";
import { Column } from "primereact/column";
import { AdminService, Reporting } from "../../api";
import { Header } from "../layout";
import Grid from "@material-ui/core/Grid";

export const AdministratorView = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const childRef = useRef();
  const dt = useRef(null);

  const usersColumns = [
    { title: "Idea", field: "idea", width: "800%" },
    { title: "Employee", field: "name", width: "20%" },
    { title: "Date", field: "creationDate", width: "20%" },
    { title: "Theme", field: "theme", width: "20%" },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setLoading(true);
    AdminService.getUsers().then(
      (data) => {
        setData(recordData(data.ideaList));
        setLoading(false);
      },
      (error) => {
        childRef?.current?.showToast({
          type: "warn",
          title: "Error response",
          message: error,
          durable: true,
        });
        setLoading(false);
      }
    );
  };

  const recordData = (users) => {
    let list = [];
    users?.map((user) =>
      list.push({
        idea: user.idea,
        name: user.username,
        creationDate: `${new Date(
          user.creationDate
        ).toLocaleDateString()} ${new Date(
          user.creationDate
        ).toLocaleTimeString()}`,
        theme: user.theme.theme,
      })
    );
    return list;
  };

  const columns = usersColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.header !== "Action"}
        className={`p-columns ${col.header === "Name" ? "p-columns-name" : ""}`}
        //filterElement={elementFilter(col.field)}
        filter={col.header !== "Action"}
      />
    );
  });

  const downloadXLSXFile = (e) => {
    e.preventDefault();

    if (startDate !== "" && endDate !== "") {
      let url =
        "startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&fileExtension=xlsx";
      Reporting.downloadReport(url, startDate, endDate, setLoadingDownload);
    } else {
      Reporting.downloadReport(null, null, null, setLoadingDownload);
    }
  };

  return (
    <div>
      <Header />
      {loading ? (
        <ProgressLoader />
      ) : (
        <div style={{ padding: "20px" }}>
          <form onSubmit={downloadXLSXFile}>
            <Paper elevation={3} style={{ marginBottom: "20px" }}>
              <Grid
                item
                xs={12}
                spacing={2}
                style={{ padding: "30px" }}
                container
                justify="center"
                direction="row"
                alignContent="center"
              >
                <Grid item xs={12} sm={6}>
                  <Input
                    id="startDate"
                    type="date"
                    placeholder="Start date"
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    id="endDate"
                    type="date"
                    placeholder="End date"
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date.target.value);
                    }}
                  />
                </Grid>

                <Grid xs={12} sm={12}>
                  <Grid item sm={3} alignContent={"flex-end"}>
                    <Button
                      rounded
                      className="btn btn-primary btn-block"
                      type="submit"
                      label={Utils.labelTemplate(
                        <>
                          <i class="fas fa-cloud-download-alt"></i> Download
                        </>,
                        loadingDownload,
                        "Logging in..."
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <MaterialTableCustom
              title="List of ideas"
              columns={usersColumns}
              data={data}
              editTable={false}
            />
          </form>
        </div>
      )}
      <ToastNotification ref={childRef} />
    </div>
  );
};

import React, {Fragment, useState} from "react";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';


function CustomKeyboardDatePicker(props) {

  return (
    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
      <KeyboardDatePicker

        clearable
        margin="dense"
        //required
        inputVariant="outlined"
        label={props.label}
        name={props.name}
        fullWidth
        onChange={(data) => {
          props.onChange(data)
        }}
        value={props.value===undefined?null:props.value}
        format="dd/MM/yyyy"
        minDate={props.max}
        maxDate={props.min}
        InputAdornmentProps={{position: "end"}}
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomKeyboardDatePicker;

import React from "react";

export const Button = (props) => {
  return (
    <div class="form-group m-0">
      <button
        type={props.type}
        className="btn btn-primary btn-block"
        style={{ borderRadius: ` ${props.rounded && "50px"}` }}
      >
        {props.label}
      </button>
    </div>
  );
};

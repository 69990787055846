import React, { useEffect, useRef, useState } from "react";
import {
  CustomInput,
  ToastNotification,
  Button,
  ProgressLoader,
} from "../shared";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { QuestionnaireService } from "../../api";
import MenuItem from "@material-ui/core/MenuItem";
import { Header } from "../layout";
import Utils from "../../utils";

export const MasterPage = (props) => {
  const childRef = useRef();
  const childRef2 = useRef();
  const [data, setData] = useState({
    idea: "",
    theme: {
      id: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [themes, setThemes] = useState("");
  const [selectedtheme, setSelectedtheme] = useState({
    theme: {
      id: "",
    },
  });
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    getThemes();
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setLoading(true);
    QuestionnaireService.getUserInfo().then(
      (data) => {
        setUserInfo(data.userDetails);
        setLoading(false);
      },
      (error) => {
        childRef?.current?.showToast({
          type: "warn",
          title: "Error response",
          message: error,
          durable: true,
        });
        setLoading(false);
      }
    );
  };

  const getThemes = () => {
    setLoading(true);
    QuestionnaireService.getThemes().then(
      (data) => {
        setThemes(data.themeList);
        setLoading(false);
      },
      (error) => {
        childRef?.current?.showToast({
          type: "warn",
          title: "Error response",
          message: error,
          durable: true,
        });
        setLoading(false);
      }
    );
  };

  const submitIdea = (e) => {
    e.preventDefault();
    //e.target.reset();
    setSubmitLoading(true);
    QuestionnaireService.addNewIdea(data).then(
      (data) => {
        setSubmitLoading(false);
        //window.location.reload()
        childRef?.current?.showToast({
          type: "success",
          title: "Success response",
          message: data.message,
          durable: false,
        });
        childRef2?.current?.showToast({
          type: "success",
          title: "Success response",
          message:
            "Votre idée à bien été enregistrée, merci pour votre contribution !",
          durable: false,
        });
        setData({
          idea: "",
          theme: {
            id: "",
          },
        });
        //document.getElementById("theme").clearContext();
        //document.getElementById("name").reset();
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 1500);
      },
      (error) => {
        setLoading(false);
        childRef?.current?.showToast({
          type: "warn",
          title: "Error response",
          message: error,
          durable: false,
        });
      }
    );
  };

  return (
    <div>
      <Header />
      {loading ? (
        <ProgressLoader />
      ) : (
        <div>
          <form
            onSubmit={submitIdea}
            //onReset={handleFormReset}
          >
            <Paper
              elevation={3}
              style={{
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div
                className="master-container"
                style={{
                  padding: 10,
                }}
              >
                <h3 style={{ color: "red", fontWeight: "bold" }}>
                  Bienvenue {userInfo.fullName}
                  <br />
                </h3>

                <h7>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Département :
                  </span>{" "}
                  {userInfo.department}
                </h7>

                <br />

                <h7>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Service :
                  </span>{" "}
                  {userInfo.service}
                </h7>

                <br />

                <h7>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Région :
                  </span>{" "}
                  {userInfo.region}
                </h7>
                <br />
                <h7>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Matricule :
                  </span>{" "}
                  {userInfo.employeeId}
                </h7>
                <br />
                <h7>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    Numéro de téléphone :
                  </span>{" "}
                  {userInfo.phoneNumber}
                </h7>
              </div>
            </Paper>

            <Paper
              elevation={3}
              style={{
                marginTop: 10,
                marginLeft: 20,
                marginRight: 20

              }}
            >
              <div className='master-container2'
                   style={{
                     padding: 10,
                   }}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h5 style={{ color: "black", fontWeight: "bold" }}>
                    Cet espace est le vôtre. Vous pouvez proposer l’idée de
                    votre choix, que vous estimez intéressante pour dynamiser,
                    développer ou encore faire innover notre entreprise.
                    <br />
                    <br />
                    Si votre idée remporte du succès et sera retenue par Ooredoo
                    Algérie, vous serez récompensé(e) !
                    <br />A vos claviers !
                  </h5>
                </Grid>

                <Grid item xs={12} sm={3} style={{ margin: "auto" }}>
                  <p> Choisissez un service</p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {themes && (
                    <CustomInput
                      id="theme"
                      select={true}
                      label="Thème"
                      style={{ width: "100%" }}
                      //value={selectedtheme.theme}
                      onChange={(e, newValue) => {
                        setData({
                          ...data,
                          theme: {
                            id: newValue.props.value,
                            theme: newValue.props.children,
                          },
                        });
                        setSelectedtheme({
                          theme: {
                            id: newValue.props.value,
                            theme: newValue.props.children,
                          },
                        });
                      }}
                    >
                      {themes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.theme}
                        </MenuItem>
                      ))}
                    </CustomInput>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} />

                <Grid item xs={12} sm={3} style={{ margin: "auto" }}>
                  <p>Quelle est votre idée ?</p>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ maxLength: 4000 }}
                    label="Tapez ici"
                    variant="outlined"
                    placeholder="Tapez ici"
                    type="text"
                    //style={{borderRadius: '50%', width: 410}}
                    style={{ width: "100%" }}
                    multiline
                    required
                    rows={3}
                    rowsMax={20}
                    name="id"
                    margin="dense"
                    //value={this.state.message}
                    onChange={(e) => {
                      setData({ ...data, idea: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} />
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={3}>
                  <Button
                    rounded
                    type="submit"
                    disabled={submitLoading}
                    label={Utils.labelTemplate(
                      <>
                        <i className="fas fa-check-circle" /> Valider
                      </>,
                      submitLoading,
                      "Logging in..."
                    )}
                  ></Button>
                </Grid>
              </Grid>
              </div>
            </Paper>
          </form>
        </div>
      )}
      {/* <ToastNotification ref={childRef} position="bottom-left" /> */}
      <ToastNotification ref={childRef2} position="top-center" />
    </div>
  );
};

import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { LoginPage } from "../pages/login-page";
import { MasterPage } from "../pages/master-page";
import { AdministratorView } from "../pages/administrator-view";
import { PrivateRoute } from "./private-route";
import Utils from "../../utils";
import { AccessDenied } from "./access-denied";

export const Routes = () => {
  return (
    <Router history={useHistory}>
      <Switch>
        {/* <Route path="/test">
          <div className="container m-2" style={{ backgroundColor: "red" }}>
            cc
          </div>
        </Route> */}
        <Route path="/login" component={LoginPage}>
          {Utils.isUserLogged() && <Redirect to="/home" />}
        </Route>
        <PrivateRoute path="/home" component={MasterPage} />

        <PrivateRoute
          path="/admin"
          isAdmin={true}
          component={AdministratorView}
        />

        {/*: <AccessDenied/>}*/}
        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
};
